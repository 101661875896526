import React, { useRef, useEffect } from 'react';
import { Country, renderCountryShape } from './map-content';
import zoom, { PanZoom } from 'panzoom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

interface MapProps {
    countries: Country[];
    onClickVisited: (country: Country) => void;
}

function isClickable(target: HTMLElement | null): boolean {
    while (target) {
        if (target.classList.contains('visited') || target.tagName === 'BUTTON') {
            return true;
        }
        target = target.parentElement;
    }
    return false;
}

let zoomControl: PanZoom;

const Map: React.FC<MapProps> = ({ countries, onClickVisited }) => {
    const ref = useRef<SVGSVGElement>(null);
    useEffect(() => {
        if (ref.current) {
            zoomControl = zoom(ref.current, {
                zoomSpeed: 0.5,
                smoothScroll: true,
                maxZoom: 10,
                minZoom: 1,
                onTouch: elem => !isClickable(elem.target as HTMLElement),
                zoomDoubleClickSpeed: 1,
            });
        }
    }, [ref.current]);

    function zoomBy(e: React.SyntheticEvent, value: number) {
        e.preventDefault();
        e.stopPropagation();
        if (ref.current && zoomControl) {
            const rect = ref.current.getBBox();
            const cx = rect.x + rect.width / 2;
            const cy = rect.y + rect.height / 2;
            zoomControl.smoothZoom(cx, cy, value);
        }
    }

    function zoomExtents(e: React.SyntheticEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (zoomControl) {
            zoomControl.moveTo(0, 0);
            zoomControl.zoomAbs(0, 0, 1);
        }
    }

    return (
        <div className="map-wrapper">
            <div>
                <svg className="map" ref={ref}>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-30 61 2754 1378">
                        {countries.map(country => (
                            <g
                                key={country.code}
                                className={'landxx country' + ((country.postsCount || 0) > 0 ? ' visited' : '')}
                                onClick={(country.postsCount || 0) > 0 ? () => onClickVisited(country) : undefined}
                            >
                                <title>{country.name}</title>
                                {renderCountryShape(country.code)}
                            </g>
                        ))}
                    </svg>
                </svg>
                <div>
                    <button className="map-button" onClick={e => zoomBy(e, 2)}>
                        <FontAwesomeIcon icon={faPlus} title="Zoom In" />
                    </button>
                    <button className="map-button" onClick={e => zoomBy(e, 0.5)}>
                        <FontAwesomeIcon icon={faMinus} title="Zoom Out" />
                    </button>
                    <button className="map-button" onClick={zoomExtents}>
                        <FontAwesomeIcon icon={faExpand} title="Zoom Extents" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Map;
